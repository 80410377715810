import React from "react";
import dynamic from "next/dynamic";
import "../app/globals.css";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import { useSelector } from "react-redux";
import { RootState } from "@/lib/store";
import { decremented } from "@/lib/features/slices/screensSlice";

const Screen = () => {
  const { screenId } = useAppSelector((state) => state.screenDetails);
  const screensDetails = useSelector((state: RootState) => state.screenDetails);
  const dispatch = useAppDispatch();
  const getCurrentScreen = () => {
    switch (screenId) {
      case 1:
        return "register";
      case 2:
        return "verificationCode";
      case 3:
        return "signup";
      case 4:
        return "paymentMethod";
      case 5:
        return "paymentSuccess";
      case 6:
        return "congratulation";
      case 8:
        return "partnerScreen";
      default:
        return "register";
    }
  };
  let ScreenComponent;
  try {
    // Dynamically import the screen component based on the screen parameter
    ScreenComponent = dynamic(
      () => import(`../app/screens/${getCurrentScreen()}`)
    );
  } catch (error) {
    // Handle the case when the screen component does not exist or fails to load
    return (
      <div className="common-container" id="container1">
        <h1>Page not found</h1>
        <p>The requested page does not exist.</p>
      </div>
    );
  }

  return (
    <div
      className={`common-container ${
        screenId == 5 && "payment-success-cover"
      } ${screenId == 6 && "congrats-cover"}`}
      id="container1"
    >
      <div className="logo">
        {screensDetails.showBackBtn === true && (
          <div onClick={() => dispatch(decremented())} className="backBtn">
            <img src="./images/back-btn.svg" alt="back" />
          </div>
        )}
        {screensDetails.showLogo === true && <img src="./images/Logo.svg" />}
      </div>
      <ScreenComponent />
    </div>
  );
};

export default Screen;
